@import "../../index.scss";

.navbar {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 2em 6em;
  margin-bottom: 1em;

  @include smt {
    flex-direction: column;
  }

  &__logo {
    width: 170px;
    height: 100%;
    object-fit: contain;

    @include smt {
      margin-bottom: 1.5em;
    }
  }

  //STYLING FOR WALLETMULTIBUTTON
  &__wallet-button {
    line-height: normal;
    padding: 1em;
    border: 1px solid var(--primary-green-color);
    color: white;
    display: flex;
    align-items: center;
    font-weight: 600;
    position: relative;
    transition: all 0.3s ease-out;
    border-radius: 0.5em;
    cursor: pointer;
    &:hover {
      filter: brightness(0.8);
    }
  }
}
