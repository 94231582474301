.bulk-modal {
  &__title {
    color: var(--primary-green-color);
    font-size: 2em;
    margin: 1em 0 0.7em 0;
  }

  &__description {
    color: white;
    font-size: 1em;
    font-weight: 600;
  }

  &__transactions {
    margin: 3em 0;
  }

  &__warning {
    width: 100%;
    padding: 1em 1.5em;
    background: rgba(253, 169, 43, 0.1);
    color: #fda92b;
    font-size: 0.8em;
    border-radius: 8px;
    margin-top: 1em;
    text-align: center;

    &.align-left {
      text-align: left;
    }
  }
}
