@import "../../index.scss";

.connect-wallet {
  color: white;
  background-color: var(--primary-color);
  border-radius: 1em;
  padding: 3em;
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  margin-top: 4em;
  flex-direction: column;

  @include md {
    font-size: 1.2em;
    width: 90%;
    h3 {
      text-align: center;
    }
  }

  @include sm {
    font-size: 1em;
  }

  &__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    margin-bottom: 0.7em;
    text-align: center;
  }

  //STYLING FOR WALLETMULTIBUTTON
  &__wallet-button-home {
    line-height: normal;
    padding: 1em;
    border: 0px;
    background: var(--primary-green-color);
    display: flex;
    align-items: center;
    font-weight: 600;
    position: relative;
    transition: all 0.3s ease-out;
    color: black;
    border-radius: 0.5em;
    cursor: pointer;

    //overwriting the existing class for button on hover
    &:not([disabled]):hover {
      background-color: var(--primary-green-color);
      filter: brightness(0.8);
    }
  }
}
