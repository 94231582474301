.edit-trait-value {
  padding: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  border: 1px solid var(--green-hover-color);
  filter: drop-shadow(--green-hover-color, 0 0 15px);
  border-radius: 4px;
  cursor: pointer;

  &--non-active {
    border: 1px solid var(--red-color);
    filter: drop-shadow(--red-color, 0 0 15px);
  }
  &--new {
    border: 1px solid var(--linear-second-color);
    filter: drop-shadow(--linear-second-color, 0 0 15px);
  }

  &__selected {
    border: 1px solid yellow;
    color: 1px soid yellow;
  }

  &__value {
    font-size: 0.8em;
    font-weight: 500;
    color: var(--primary-green-color);

    &--non-active {
      color: var(--red-color);
    }

    &--new {
      color: var(--linear-second-color);
    }

    &--selected {
      color: yellow;
    }
  }
}
