.create-config {
  margin: 1em 0;
  &__title {
    color: white;
    font-size: 1.5em;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__item {
    color: white;
    margin: 0.5em 0;
  }

  &__item-key {
    font-weight: 600;
    margin: 1em 0;
  }

  &__item-value {
    font-size: 0.7em;
    background-color: #292929;
    padding: 0.5em;
    border-radius: 0.5em;
  }

  &__item-value-map {
    display: flex;
    flex-wrap: wrap;
    gap: 0.7em;
  }
}
