@import "../../index.scss";

.main-button {
  padding: 1em;
  border: 0px;
  background: var(--primary-green-color);
  display: flex;
  align-items: center;
  gap: 1em;
  font-weight: 600;
  position: relative;
  transition: all 0.3s ease-out;
  color: black;
  border-radius: 0.5em;
  cursor: pointer;
  &:hover {
    filter: brightness(0.8);
  }

  @include md {
    font-size: 0.8em;
  }

  &--not-selected {
    background-color: rgba(255, 255, 255, 0.07);
    color: white;
  }

  @include sm {
    font-size: 0.7em;
  }
}
