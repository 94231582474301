.nft-list {
  &__list {
    display: grid;
    grid-gap: 1rem;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    margin-top: 1em;
  }
}

.page-item {
  font-size: 0.7em;
  padding: 0.5em;
  color: var(--primary-green-color);
  background-color: #222937;

  &:nth-child(1) {
    border-top-left-radius: 0.5em;
    border-bottom-left-radius: 0.5em;
  }
  &:nth-last-child(1) {
    border-top-right-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
  }

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

.pagination {
  background-color: transparent;
  display: flex;
  margin: 1em 0em;
  justify-content: end;
}

.active {
  color: var(--primary-green-color);
  background-color: #595a5da1;
}
