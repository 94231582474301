.collection-nfts {
  margin-top: 2em;

  &__nfts-title {
    font-size: 1.2em;
    color: var(--primary-green-color);
    margin-bottom: 0.5em;
  }

  &__nft-item {
    display: flex;
    gap: 1em;
    align-items: center;
    padding: 0.7em;
    background-color: #222937;
    border-radius: 0.5em;
    font-size: 0.7em;
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }

  &__nft-img {
    width: 30px;
    height: 30px;
    border-radius: 0.5em;
  }

  &__nft-name {
    color: white;
    font-weight: 700;
  }

  &__skeleton-list {
    display: grid;
    grid-gap: 1rem;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    margin-top: 1em;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__tab-button {
    background-color: transparent;
    border-radius: 0.5em;
    border: 1px solid var(--primary-green-color);
    padding: 0.5em 1em;
    color: white;
    font-weight: 600;
    font-size: 0.8em;

    &:nth-child(1) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:nth-child(2) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &--active {
      background-color: var(--primary-green-color);
      color: black;
    }

    &:hover {
      cursor: pointer;
      opacity: 0.9;
    }
  }
}
