@import "../../index.scss";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 30;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;

  &__container {
    overflow: auto;
    padding: 0em 3.8em;

    @include md {
      padding: 0em 2em;
    }
  }

  &__content {
    max-width: 900px;
    max-height: 90vh;
    background: var(--modal-background-color);
    padding: 3em 0.2em;
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    width: 70vw;
    position: relative;

    @include sm {
      width: 90vw;
    }
  }

  &__close-icon {
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;

    @include sm {
      right: 15px;
      top: 15px;
    }
  }
}
