.home-page {
  padding: 0 10vw;

  &__collection {
    width: 60%;
  }
  &__collection-title {
    color: var(--primary-green-color);
    font-size: 1.5em;
    margin: 1em 0;
    font-weight: 600;
  }

  &__collection-input {
    background-color: #222937;
    border-radius: 0.5em;
    border: 1px solid transparent;
    padding: 1em 1.5em;
    color: white;
    font-size: 0.9em;
    display: flex;
    width: 70%;
    &:focus-visible {
      outline: inherit;
    }
  }

  &__input-error {
    color: rgb(192, 12, 12);
    font-size: 0.875em;
    margin: 0.5em 0 0.5em 1em;
  }

  &__collection-find {
    display: flex;
    align-items: center;
    gap: 1em;
  }

  &__config-info-title {
    color: white;
    font-weight: 700;
    margin: 1em 0;
  }

  &__nfts {
    margin-top: 2em;
  }

  &__nfts-title {
    font-size: 1.5em;
    color: var(--primary-green-color);
    margin-bottom: 0.5em;
  }

  &__nft-item {
    display: flex;
    gap: 1em;
    align-items: center;
    padding: 1em;
    background-color: #222937;
    border-radius: 0.5em;
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }

  &__nft-img {
    width: 30px;
    height: 30px;
    border-radius: 0.5em;
  }

  &__nft-name {
    color: white;
    font-weight: 700;
  }

  &__nfts-list {
    display: grid;
    grid-gap: 1rem;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    margin-top: 1em;
  }

  &__authority {
    margin-top: 2em;
    h4 {
      color: white;
      font-size: 1.2em;
    }
  }
}
