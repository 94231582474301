.add-new-trait {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  img {
    width: 1.25em;
    cursor: pointer;
  }
  &__title {
    font-size: 1em;
    font-weight: bold;
  }
  &__span {
    color: var(--linear-second-color);
  }

  &__error {
    margin-top: 0.5em;
    color: var(--red-color);
    font-size: 0.8em;
  }

  &__buttons {
    display: flex;
    width: 100%;
    margin-top: 2em;
    justify-content: space-between;
  }

  &__cancel {
    background-color: transparent;
    border: 1px solid white;
    font-size: 0.8em;
    font-weight: bold;
    padding: 1em;
    cursor: pointer;
    color: white;
    border-radius: 4px;
  }

  &__input {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 0.5em;
    input {
      width: 100%;
    }
  }

  &__button {
    width: 100%;
    padding: 1em;
    border-radius: 4px;
    margin-top: 2em;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid var(--linear-second-color);
    color: var(--linear-second-color);
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
  }
}
