@import url("https://fonts.googleapis.com/css2?family=Epilogue:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Epilogue:wght@500&family=IBM+Plex+Sans&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Syne&display=swap");

:root {
  --dark-blue-color: #040a16;
  --primary-color: rgba(255, 255, 255, 0.03);
  --primary-green-color: #2bfaaf;
  --green-hover-color: #2bb77d;
  --modal-background-color: #0c121d;
  --red-color: #fd2b2b;
  --linear-second-color: #38d6f9;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style-type: none;
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  background: var(--dark-blue-color);
}

h1,
h2,
h3,
h4 {
  font-family: "Montserrat", sans-serif;
  text-align: start;
}

button[disabled] {
  opacity: 0.6;
}

p,
a,
li,
b,
input,
textarea,
span,
strong,
::placeholder,
button {
  font-family: "Montserrat", sans-serif;
}

//REACT NOTIFICATION CSS

div .notification-container {
  top: 6px;
  right: 13px;

  @media (max-width: 769px) {
    right: 0;
  }
}

div .notification {
  font-family: "Montserrat", sans-serif;
  border-radius: 10px;
  font-size: 0.8em;
  box-shadow: unset;
  color: white;
  word-break: break-word;
}

div .notification .title {
  font-size: 0.9em;
}

div .notification .description {
  font-size: 0.9em;
}

div .notification-error {
  background-color: rgb(121, 7, 7);
}

//MIXINS FOR RESPONSIVE DESIGN

$small-width: 425px;
$medium-width: 768px;
$small-tablet-width: 548px;

@mixin sm {
  @media (max-width: #{$small-width }) {
    @content;
  }
}

//breaks by default, so added a breakpoint to solve that specific case
@mixin smt {
  @media (max-width: #{$small-tablet-width}) {
    @content;
  }
}

@mixin md {
  @media (max-width: #{$medium-width}) {
    @content;
  }
}
