.nft-item-modal {
  &__nft-info {
    display: flex;
    gap: 1em;
    h3 {
      font-size: 1.7em;
      color: white;
      color: var(--primary-green-color);
    }

    img {
      width: 150px;
      height: fit-content;
      border-radius: 0.5em;
    }
  }

  &__basic-info-card {
    margin: 1em 0;
  }

  &__basic-info-label {
    font-size: 0.875em;
    color: var(--primary-green-color);
    font-weight: 700;
  }

  &__basic-info-value {
    font-size: 1em;
    color: white;
    font-weight: 700;
  }

  &__traits-info {
    color: white;
    margin: 1em 0;
  }
  &__traits {
    display: grid;
    grid-gap: 1rem;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    margin-top: 1em;
    margin-bottom: 2em;
  }
  &__trait-info {
    padding: 0.5em;
    background-color: var(--primary-color);
    border: 1px solid var(--primary-green-color);

    border-radius: 0.5em;
  }

  &__trait-info-key {
    font-size: 0.8em;
    color: var(--primary-green-color);
    margin-bottom: 0.2em;
  }

  &__trait-info-value {
    font-size: 0.8em;
    color: white;
    font-weight: 600;
    margin-bottom: 0.2em;
  }

  &__traits-info-header {
    display: flex;
    justify-content: space-between;

    button {
      color: black;
      background-color: var(--primary-green-color);
      border: none;
      padding: 0.5em;
      border-radius: 0.5em;
      font-weight: 600;

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }
}
