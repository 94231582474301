.config-buttons {
  display: flex;
  flex-direction: row;
  gap: 0.25em;
  &__button {
    background: transparent;
    font-size: 0.8em;
    font-weight: bold;
    border-radius: 4px;
    padding: 0.25em 0.5em;
    text-align: center;
    cursor: pointer;

    &--add {
      color: var(--linear-second-color);
      border: 1px solid var(--linear-second-color);
    }

    &--remove {
      color: var(--red-color);
      border: 1px solid var(--red-color);
    }

    &--activate {
      color: var(--primary-green-color);
      border: 1px solid var(--primary-green-color);
    }
  }
}
